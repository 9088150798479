import { GoogleMap, Marker } from "@react-google-maps/api"
import React from "react"

import marker_icon from "@images/icons/map-pin-circle.svg"
import { mapStyles } from "./styles"
import useLoadMap from "./useLoadMap"

const SingleMap = ({ lat, lng, noMarker }) => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  }

  const center = {
    lat,
    lng,
  }

  const position = {
    lat,
    lng,
  }

  const [currentInfo, setCurrentInfo] = React.useState("")

  const onUnmount = React.useCallback(function callback() {}, [])

  const onMarkerClick = (infoId) => {
    if (infoId === currentInfo) {
      setCurrentInfo("")
    } else {
      setCurrentInfo(infoId)
    }
  }

  const { isLoaded } = useLoadMap()

  return isLoaded ? (
    <div className="search-map-single" id="map">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onUnmount={onUnmount}
        zoom={14}
        options={{
          styles: mapStyles,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        {!noMarker && (
          <Marker
            position={position}
            icon={marker_icon}
            onClick={(e) => onMarkerClick("info-1", e)}
          />
        )}
      </GoogleMap>
    </div>
  ) : null
}

export default React.memo(SingleMap)