import BannerPropertyImages from "@components/BannerPropertyImages/BannerPropertyImages"
import FloatingCTAShell from "@components/FloatingCTAShell/FloatingCTAShell"
import Layout from "@components/Layout/Layout"
import PropertyAreaguide from "@components/PropertyAreaguide/PropertyAreaguide"
import PropertyBreadcrumb from "@components/PropertyBreadcrumb/PropertyBreadcrumb"
import PropertyInfo from "@components/PropertyInfo/PropertyInfo"
import PropertyLocation from "@components/PropertyLocation/PropertyLocation"
import PropertyMortgageCalc from "@components/PropertyMortgageCalc/PropertyMortgageCalc"
import PropertyNegoCard from "@components/PropertyNegoCard/PropertyNegoCard"
import SimilarProperties from "@components/SimilarProperties/SimilarProperties"
import Animation from "@components/elements/Animation"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import Seo from "@components/seo"
import useTeams from "@hooks/dataHooks/useTeams"
import {
  bookAViewingURL,
  mortgagesURL,
  propertyDetailRentURL,
  propertyDetailSaleURL,
} from "@lib/urls"
import { addRollWhatsapp, formatNumber, getWhatsAppURL, gtagReportConversion } from "@lib/utils"
import "@styles/property-details.scss"
import { Link, graphql, navigate } from "gatsby"
import { isEmpty } from "lodash"
import { PhoneIcon } from "lucide-react"
import React, { createRef, useEffect, useState } from "react"
import { Container } from "react-bootstrap"

const PropertyDetailTemplate = ({ data }) => {
  const property = data?.allProperties?.nodes?.length > 0 ? data?.allProperties?.nodes[0] : data?.allPropertiesUnpublished?.nodes[0]
  const area = data?.strapiAreaGuide
  // console.log("ddd", property)
  if (!property) {
    return typeof window !== "undefined" && navigate("/404/")
  }
  const building = property?.building?.length > 0 &&  property?.building[0] || "Property"
  const action = property?.search_type === "sales" ? "for sale" : "for rent"
  const bedrooms = property?.bedroom
  const bedroomsText = Number(bedrooms) > 1 ? "bedrooms" : "bedroom"
  const address = property?.display_address
  const price = formatNumber(property?.price)

  const { phone } = property?.crm_negotiator_id

  const isSales = property?.search_type === "sales"
  const basePath = isSales ? propertyDetailSaleURL : propertyDetailRentURL
  const propertyURL = `${basePath}${property?.slug}${property?.strapi_id}/`
  const message = `Hi, I am interested in this property [ ${process.env.GATSBY_SITE_URL+propertyURL} ], the reference number is ${property?.crm_id}. Could you please provide me with more information about it? Thank you.`

  const propertyData = {
    address: property?.display_address,
    description: property?.description,
    bathrooms: property?.bathroom,
    bedrooms: property?.bedroom,
    building: property?.building?.length > 0 &&  property?.building[0],
    image: property?.images?.length > 0 && property?.images[0],
    price: property?.price,
    size: property?.floorarea_min,
    crmID: property?.crm_id,
    slug: property?.slug,
    searchType: property?.search_type,
    strapiID: property?.strapi_id,
    message: message,
  }

var team_data = useTeams()
let team_key;
if(team_data){
  if (property?.crm_negotiator_id?.id != undefined) {
      for (let k in team_data) {
          if (property?.crm_negotiator_id?.id == team_data[k].negotiator_id) {
              team_key = k;
              break;
          }
      }
  }
}

useEffect(() => {
  if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'product_id': property?.crm_id });
  }
}, [property]);
let nearby_places = [
  {label:"Universities", value:"university"},
  {label:"Dentists", value:"dentist"},
  {label:"Train Stations", value:"train_station"},
  {label:"Cafes", value:"cafe"},
  {label:"Hospitals", value:"hospital"},
  {label:"Bars", value:"bar"},
  {label:"Libraries", value:"library"}
]
const myRef = createRef();
    
const[place_type, setPlaceType] = useState(null);
const onChangePlace = (place) =>{
  setPlaceType(place);
  myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "center" }); 
}
  return (
    <>
      <Layout
        pageData={{
          page_class: "property-detail-page",
          layout: "property-detail",
        }}
      >
        <PropertyBreadcrumb property={property} />
        <Container>
         
          <BannerPropertyImages property={property} />
          <div className="property-detail-body">
            <Animation className="left-section">
              <PropertyInfo property={property} />
              {isSales && property?.publish && (
                <PropertyMortgageCalc
                  propertyPrice={property.price}
                  cta_text={{
                    text: "Click here to get started with your mortgage",
                    cta: {
                      cta_label: "Apply for a mortgage",
                      custom_link: mortgagesURL,
                    },
                  }}
                />
              )}
              <div ref={myRef}></div>
              <PropertyLocation
                lat={property.latitude}
                lng={property.longitude}
                place_type={place_type}
                nearby_places={nearby_places}
                onChangePlace={onChangePlace}
              />
              
              <PropertyAreaguide property={property} area={area} />
            </Animation>
            {property?.publish &&<Animation
              className="right-section"
              wrapperClassName="right-section-wrap"
            >
              {!isEmpty(property) &&
              <PropertyNegoCard
                negoData={property.crm_negotiator_id}
                negoID={property.crm_negotiator_id?.brn_number}
                property={propertyData}
                propertyURL={propertyURL}
                message={message}
                team_data={team_data}
                team_key={team_key}

              />}
            </Animation>}
          </div>
        </Container>
        <SimilarProperties propId={property.strapi_id} />
      </Layout>
      {property?.publish &&
      <FloatingCTAShell message={message}>
        <Link
          to={bookAViewingURL}
          className="button button-orange"
          state={{
            property: propertyData,
            propertyURL: propertyURL,
            email:property?.crm_negotiator_id?.email,
            crm_negotiator_id:property?.crm_negotiator_id?.id
          }}
          onClick={() => {
            localStorage?.setItem("propertyURL", propertyURL)
          }}
        >
          <span>Book a Viewing</span>
          <MailIcon color="white" />
        </Link>
        {phone ? (
          <>
            <a
              href={`tel:${phone}`}
              className="button button-gray whatsapp-link"
              onClick={() => gtagReportConversion(`tel:${phone}`)}
            >
              <span>Call</span>
              <PhoneIcon />
            </a>
            <a
              href={getWhatsAppURL(phone, message)}
              className="button button-gray whatsapp-link"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <span>WhatsApp</span>
              <WhatsAppIcon />
            </a>
          </>
        ) : (
          <>
            <a
              href={`tel:97143233609`}
              className="button button-orange"
              onClick={() => gtagReportConversion(`tel:97143233609`)}
            >
              <span>Call</span>
              <MobileIcon color="white" />
            </a>
            <a
              href={getWhatsAppURL("97143233609", message)}
              className="button button-gray whatsapp-link"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <span>WhatsApp</span>
              <WhatsAppIcon />
            </a>
          </>
        )}
        <div
            data-mapropertyid={property?.crm_id}
            className="ma-sb-myacc ma-save-property button button-primary-light"
          >
            <span className="property-save icon-save" />
            <span className=" property-save icon-saved" />
            <span className="save-text">Save</span>
            <span className="saved-text">Saved</span>
          </div>
      </FloatingCTAShell>}
    </>
  )
}

export default PropertyDetailTemplate

export const Head = ({ data }) => {
  const property = data?.allProperties?.nodes?.length > 0 ? data?.allProperties?.nodes[0] : data?.allPropertiesUnpublished?.nodes[0]

  const building = property?.building?.length > 0 && property?.building[0] || "Property"
  const action = property?.search_type === "sales" ? "for sale" : "for rent"
  const bedrooms = property?.bedroom
  const bedroomsText = Number(bedrooms) > 1 ? "bedrooms" : "bedroom"
  const bedtxt = property?.bedroom === 0 && building !== "Land" ? "with Studio" : property?.bedroom !== 0 ? `with ${bedrooms} ${bedroomsText}` : ""
  const address = property?.display_address
  const price = formatNumber(property?.price)
  const crmid = property?.crm_id

  return (
    <Seo
      title={`${building} ${action} ${bedtxt} in ${address} at AED ${price} [${crmid}]`}
      description={`Know the details of ${building} ${action} with ${bedrooms} ${bedroomsText} in ${address} at ${price} [${crmid}]. Book a viewing with Provident Real Estate to get assistance in finding the right ${building} for you.`}
      img={property?.images?.length > 0 && property?.images[0]?.url}
    />
  )
}

export const query = graphql`
  query ($crm_id: String, $area: String) {
    allProperties(filter: { crm_id: { eq: $crm_id } }) {
      nodes {
        ...propertyFragment
      }
    }
    allPropertiesUnpublished(filter: { crm_id: { eq: $crm_id } }) {
      nodes {
        ...propertiesUnpublishedFragment
      }
    }
    strapiAreaGuide(title: { eq: $area }) {
      ...areaguideFragment
    }
  }
`
