import { TeamContactModal } from "@components/TeamContactModal/TeamContactModal"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import { bookAViewingURL, teamsURL } from "@lib/urls"
import { addRollWhatsapp, getWhatsAppURL, gtagReportConversion } from "@lib/utils"
import { Link } from "gatsby"
import { isEmpty } from "lodash"
import React from "react"
import "./PropertyNegoCard.scss"
import { PhoneIcon } from "lucide-react"

const PropertyNegoCard = ({
  negoData,
  negoID,
  property,
  propertyURL,
  team,
  message,
  team_data,
  team_key
}) => {
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)

  const { name, phone, url, slug, email, id,  } = negoData

  let ImgDiv = null
  if (url && name) {
    if (slug) {
      ImgDiv = (
        <Link to={`${teamsURL + slug}/`} className="img-section img-zoom">
          <img src={url} alt="nego" />
        </Link>
      )
    } else {
      ImgDiv = (
        <div className="img-section">
          <img src={url} alt="nego" />
        </div>
      )
    }
  }
  else {
    ImgDiv = (
      <Link to={`${teamsURL + "laura-adams"}/`} className="img-section img-zoom">
        <img src={"https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/laura_adams_37a79dfe04.jpg"} alt="nego" />
      </Link>
    )
  }

  return (
    <div className="property-nego-card-wrap">
      <div className="top-section">
        {team && (
          <button
            className="button button-orange"
            onClick={() => setShow(true)}
          >
            <span>Email</span>
            <MailIcon color="white" />
          </button>
        )}
        {!isEmpty(property) && (
          <Link
            to={bookAViewingURL}
            className="button button-orange"
            state={{
              property: property,
              propertyURL: propertyURL,
              email:email,
              crm_negotiator_id:id
            }}
            onClick={() => {
              localStorage?.setItem("propertyURL", propertyURL)
            }}
          >
            <span>Book a Viewing</span>
            <MailIcon color="white" />
          </Link>
        )}
        {phone ? (
          <>
            <a
              href={`tel:${phone}`}
              className="button button-gray whatsapp-link"
              onClick={() => gtagReportConversion(`tel:${phone}`)}
            >
              <PhoneIcon />
            </a>
            <a
              href={getWhatsAppURL(phone, message)}
              className="button button-gray whatsapp-link"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <WhatsAppIcon />
            </a>
          </>
        ) :
        <>
            <a
              href={`tel:97143233609`}
              className="button button-gray whatsapp-link"
              onClick={() => gtagReportConversion(`tel:97143233609`)}
            >
              <PhoneIcon />
            </a>
            <a
              href={getWhatsAppURL("97143233609", message)}
              className="button button-gray whatsapp-link"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <WhatsAppIcon />
            </a>
          </>
        }
        <div
            data-mapropertyid={property?.crm_id}
            className="ma-sb-myacc ma-save-property button button-primary-light"
          >
            <span className="property-save icon-save" />
            <span className=" property-save icon-saved" />
            <span className="save-text">Save</span>
            <span className="saved-text">Saved</span>
          </div>
      </div>     
          {name ? 
      <div className="bottom-section">
        {ImgDiv}
        <div className="nego-info">
          <p className="name">{name}</p>
          {team_key >= 0 ?
          <p className="designation">{team_data[team_key]?.designation}</p> :
          <p className="designation">Consultant</p>}
          {negoID && <p className="orn-no">BRN No: {negoID}</p>}
        </div>
      </div>:
      <div className="bottom-section">
      {ImgDiv}
      <div className="nego-info">
        <p className="name">Laura Adams</p>
        <p className="designation">Director - Secondary Sales</p>
        <p className="orn-no">BRN No: 7870</p>
      </div>
    </div>}
      {!isEmpty(team) && (
        <TeamContactModal team={team} show={show} handleClose={handleClose} />
      )}
    </div>
  )
}

export default PropertyNegoCard
