import React from "react"
import "./PropertyLocation.scss"
// import SingleMapAG from "@components/SearchResults/map/SingleMapAG"
import Animation from "@components/elements/Animation"
import SingleMap from "@components/SearchResults/map/SingleMap"

const PropertyLocation = ({ lat, lng, place_type, nearby_places, onChangePlace }) => {
  if (!lat || !lng) {
    return null
  }
  return (
    <Animation animateInType="up" className="property-detail-map">
      <p className="heading" id="property-map-section">
        Location
      </p>
      <SingleMap lat={lat} lng={lng} place_type={place_type}/>
      {/* <SingleMapAG lat={lat} lng={lng} place_type={place_type} /> */}
      {/* <div className="divider" />
      <section className="link-group">
        <p className="heading">What’s nearby</p>

        <div className="what_we_are_link_row">
          <div className="what_we_are_link">
            <div className="link-block">
              <ul>
                {nearby_places.map((item, i) => {
                  return (
                    <li><a href="javascript:;" className={place_type === item.value ? "actv" : ""} onClick={() => onChangePlace(item.value)}>{item.label}</a></li>
                  )
                })}

              </ul>

              <p className="note-txt">Please note that any places of interest are from Google Places, we can’t guarantee their accuracy or relevance, and Providentestate does not endorse them in any way.</p>
            </div>
          </div>
        </div>

      </section> */}
      <div className="divider" />
    </Animation>
  )
}

export default PropertyLocation
